






























import { Component, Vue } from "vue-property-decorator";
import api from "@/api";
@Component({})
export default class AUser extends Vue {
  // 表格数据
  tableData = [];
  // 加载状态
  loading = false;
  btnloading = false
  // 总条数
  total = 0;

  isDialog = false;
  list: any = [];

  // 搜索条件
  search = {
    reviewCode: "1",
    orderId: "",
    pagination: {
      page: 0, //页码，从0开始
      pageSize: 20, //每页数量
    },
  };

  // 数据类型
  dataType = {}
  getDataType() {
    api.getConf().then((res) => {
      this.dataType = res.orderDataType
      this.searchTable()
    })
  }

  baowen = {}
  toDetail(res: any) {
    
    this.baowen = res
    // this.list= Array.from(res)
      this.isDialog = true;
  }

  // 搜索 search:true 重置翻页
  searchTable(search = false) {
    this.loading = true;
    if (search) {
      this.search.pagination = {
        pageSize: 20,
        page: 0,
      };
    }
    api
      .post("J30119", this.search)
      .then((data) => {
        this.total = data.total;
        this.tableData = data.list;
        
      })
      .finally(() => {
        this.loading = false;
      });
  }


  activated() {
    this.getDataType()
  }

}
